<template>
  <el-container style="height: 100%" @dragenter.native="dragenter">
    <el-header style="padding: 0; height: 40px">
      <div id="navigation">版本固件发布</div>
    </el-header>

    <el-container style="height: calc(100% - 40px)">
      <div style="padding: 20px 20px 0 20px">
        <el-form :inline="true" ref="dataForm" :model="dataForm" @keyup.enter.native="getDataList(true)">
          <el-form-item prop="suit">
            <el-autocomplete
              v-model="dataForm.suit"
              :fetch-suggestions="(a, b) => queryKey(a, b, 'suit')"
              placeholder="产品系列"
              clearable
            ></el-autocomplete>
          </el-form-item>
          <el-form-item prop="model">
            <el-autocomplete
              v-model="dataForm.model"
              :fetch-suggestions="(a, b) => queryKey(a, b, 'model')"
              placeholder="设备型号"
              clearable
            ></el-autocomplete>
          </el-form-item>
          <el-form-item prop="pn">
            <el-autocomplete v-model="dataForm.pn" :fetch-suggestions="(a, b) => queryKey(a, b, 'pn')" placeholder="PN码" clearable></el-autocomplete>
          </el-form-item>
          <el-form-item prop="otaVersionState">
            <el-select v-model="dataForm.otaVersionState" placeholder="版本状态" clearable>
              <el-option v-for="item in otaVersionStateOptions" :key="item.code" :label="item.name" :value="item.code"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="dateTime">
            <el-date-picker
              v-model="dataForm.dateTime"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button @click="getDataList(true)" type="primary">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="refreshForm()" style="background: rgb(30, 30, 242, 0.1); color: #5053dd">重置</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="uploadPackage()">上传版本固件</el-button>
          </el-form-item>
        </el-form>
      </div>

      <el-main style="padding: 0 20px 20px 20px; overflow: hidden">
        <div style="height: 1px; background: #d4d6d9; opacity: 0.5; margin-bottom: 22px"></div>
        <div class="main">
          <el-table
            v-if="dataList.length"
            :data="dataList"
            v-loading="tabLoding"
            header-cell-class-name="tmc_table_header"
            row-class-name="tmc_table_row"
            style="width: 100%"
            height="calc(100vh - 367px)"
          >
            <el-table-column prop="suit" header-align="center" align="center" label="产品系列"> </el-table-column>
            <el-table-column prop="model" header-align="center" align="center" label="设备型号"> </el-table-column>
            <el-table-column prop="pn" header-align="center" align="center" label="PN码" width="150"> </el-table-column>
            <el-table-column prop="otaVersion" header-align="center" align="center" label="版本号"> </el-table-column>
            <el-table-column prop="otaVersionState" header-align="center" align="center" label="版本状态" width="150">
              <template slot-scope="scope">
                <span v-if="scope.row.otaVersionState === 2">下线</span>
                <span v-else-if="scope.row.otaVersionState === 1">正式</span>
                <span v-else-if="scope.row.otaVersionState === 0">灰度</span>
              </template>
            </el-table-column>
            <el-table-column prop="state" header-align="center" align="center" label="版本固件状态" width="150">
              <template slot-scope="scope">
                <span v-if="scope.row.state === 2">已下线</span>
                <span v-else-if="scope.row.state === 1">已发布</span>
                <span v-else-if="scope.row.state === 0">未发布</span>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" header-align="center" align="center" label="时间" width="200"> </el-table-column>
            <el-table-column header-align="center" align="center" width="200" label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="official(scope.row.id)" :disabled="scope.row.otaVersionState !== 0">转正式</el-button>
                <el-button
                  type="text"
                  size="small"
                  @click="release(scope.row)"
                  :disabled="(scope.row.otaVersionState === 1 && scope.row.state !== 0) || scope.row.state === 2"
                  >发布</el-button
                >
                <el-button type="text" size="small" @click="offLine(scope.row.id)" :disabled="scope.row.otaVersionState === 2">下线</el-button>
                <el-button type="text" size="small" @click="uploadPackage(scope.row)">编辑信息</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div v-else style="height: 100%; text-align: center">
            <el-image :src="require('@/assets/img/no-data.png')" fit="contain" style="margin: 10% 0 24px 0"> </el-image>
            <div style="font-size: 14px; font-family: PingFangSC-Regular, PingFang SC; font-weight: 400; color: #73767a">啊哦~这里没有数据呢~</div>
          </div>
        </div>
      </el-main>
      <el-footer>
        <div v-if="dataList.length" style="margin-top: 20px">
          <el-pagination
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="pageIndex"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            :total="totalPage"
            layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
      </el-footer>
    </el-container>
    <!-- 灰度发布 -->
    <el-dialog title="灰度发布" :visible.sync="dialogVisible">
      <el-form>
        <el-form-item label="告知人员" class="is-required">
          <el-transfer ref="userTransfer" v-model="userIds" :data="userList" :titles="['未选择', '已选择']" filterable></el-transfer>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="toRelease">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 上传 -->
    <upload-package v-if="uploadVisible" ref="upload" @refreshDataList="getDataList"></upload-package>
  </el-container>
</template>

<script>
import UploadPackage from '@/views/UploadPackage.vue'
export default {
  components: { UploadPackage },
  name: 'Device',
  data() {
    return {
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      tabLoding: false,
      dataList: [],
      uploadVisible: false,
      dialogVisible: false,
      userList: [],
      userIds: [],
      ota: '',
      timeOutId: undefined,
      dataForm: {
        suit: '',
        model: '',
        pn: '',
        otaVersionState: undefined,
        dateTime: []
      },
      otaVersionStateOptions: [
        { code: 0, name: '灰度' },
        { code: 1, name: '正式' },
        { code: 2, name: '下线' }
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }
    }
  },
  computed: {
    isRefresh() {
      return this.$store.state.refreshFlag
    }
  },
  watch: {
    isRefresh() {
      if (this.pageIndex === 1) {
        this.getDataList()
      }
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    // 下线
    offLine(id) {
      this.$confirm(
        `<div >
          <div class="tmc_confirm_icon"></div>
          <div class="tmc_confirm_title">提示</div> 
          <div class="tmc_confirm_content">确定进行下线操作？</div> 
        </div>`,
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true,
          center: true,
          customClass: 'tmc_confirm'
        }
      )
        .then(() => {
          this.$http({
            url: this.$http.adornUrl(`/api/v1/ota/offline/${id}`),
            method: 'put',
            params: this.$http.adornParams()
          }).then((res) => {
            if (res.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => {})
    },
    // 转正式
    official(id) {
      this.$confirm(
        `<div >
          <div class="tmc_confirm_icon"></div>
          <div class="tmc_confirm_title">提示</div> 
          <div class="tmc_confirm_content">确定进行转正式操作？</div>
        </div>`,
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true,
          center: true,
          customClass: 'tmc_confirm'
        }
      )
        .then(() => {
          this.$http({
            url: this.$http.adornUrl(`/api/v1/ota/release/${id}`),
            method: 'put',
            params: this.$http.adornParams()
          }).then((res) => {
            if (res.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => {})
    },
    toRelease() {
      if (!this.userIds || this.userIds.length === 0) {
        this.$message.error('请选择告知人员')
        return
      }
      this.$http({
        url: this.$http.adornUrl(`/api/v1/ota/gray/${this.ota.id}`),
        method: 'put',
        params: this.$http.adornParams()
      }).then((res) => {
        if (res.code === 0) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.dialogVisible = false
              this.getDataList()
            }
          })

          // 保存告知人员
          this.$http({
            url: this.$http.adornUrl('/api/v1/ota/notify/users/ota'),
            method: 'post',
            params: this.$http.adornParams({
              otaVersion: this.ota.otaVersion,
              otaId: this.ota.id
            }),
            data: this.$http.adornData(this.userIds, false, 'text')
          })
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 发布
    release(ota) {
      this.$confirm(
        `<div >
          <div class="tmc_confirm_icon"></div>
          <div class="tmc_confirm_title">提示</div> 
          <div class="tmc_confirm_content">确定进行发布操作？</div> 
        </div>`,
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true,
          center: true,
          customClass: 'tmc_confirm'
        }
      )
        .then(() => {
          if (ota.otaVersionState === 0) {
            // 灰度发布
            this.userList = []
            this.userIds = []
            this.ota = ota
            this.$http({
              url: this.$http.adornUrl('/api/v1/ota/notify/users'),
              method: 'get',
              params: this.$http.adornParams()
            }).then((res) => {
              if (res.code === 0) {
                const data = res.data || []
                data.forEach((user) => {
                  this.userList.push({
                    key: user.userId,
                    label: user.loginName
                  })
                })
                this.dialogVisible = true
                this.$nextTick(() => {
                  this.$refs.userTransfer.clearQuery('left')
                  this.$refs.userTransfer.clearQuery('right')
                })
                // 前一次告知人员
                this.$http({
                  url: this.$http.adornUrl(`/api/v1/ota/last/notify/${this.ota.id}`),
                  method: 'get',
                  params: this.$http.adornParams()
                }).then((res) => {
                  if (res.code === 0) {
                    const data = res.data || []
                    this.userIds = data
                  }
                })
              } else {
                this.$message.error(res.msg)
              }
            })
          } else if (ota.otaVersionState === 1) {
            this.$http({
              url: this.$http.adornUrl(`/api/v1/ota/publish/${ota.id}`),
              method: 'put',
              params: this.$http.adornParams()
            }).then((res) => {
              if (res.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.getDataList()
                  }
                })
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        })
        .catch(() => {})
    },
    uploadPackage(row) {
      this.uploadVisible = true
      this.$nextTick(() => {
        this.$refs.upload.init(row)
      })
    },
    getDataList(flag) {
      if (flag) {
        this.pageIndex = 1
      }
      this.tabLoding = true
      this.$http({
        url: this.$http.adornUrl('/api/v1/ota/list'),
        method: 'get',
        params: this.$http.adornParams({
          pageSize: this.pageSize,
          pageNum: this.pageIndex,
          suit: this.dataForm.suit,
          model: this.dataForm.model,
          pn: this.dataForm.pn,
          otaVersionState: this.dataForm.otaVersionState,
          startTime: this.dataForm.dateTime ? this.dataForm.dateTime[0] : null,
          endTime: this.dataForm.dateTime ? this.dataForm.dateTime[1] : null
        })
      })
        .then((res) => {
          if (res.code === 0) {
            const data = res.data
            this.dataList = data.list
            this.totalPage = data.total
          } else {
            this.$message.error(res.msg)
          }
          this.tabLoding = false
        })
        .catch(() => {
          this.tabLoding = false
        })
    },
    refreshForm() {
      this.$refs.dataForm.resetFields()
    },
    queryKey(queryString, cb, type) {
      if (this.timeOutId) {
        clearTimeout(this.timeOutId)
      }

      this.timeOutId = setTimeout(() => {
        let results = []
        let keyObj = {
          suit: queryString
        }
        if (type === 'pn') {
          keyObj = {
            pn: queryString,
            model: this.dataForm.model,
            suit: this.dataForm.suit
          }
        } else if (type === 'model') {
          keyObj = {
            model: queryString,
            suit: this.dataForm.suit,
            likeSuit: 1
          }
        }
        this.$http({
          url: this.$http.adornUrl(`/api/v1/production/${type}`),
          method: 'get',
          params: this.$http.adornParams(keyObj)
        })
          .then((res) => {
            if (res.code === 0) {
              const data = res.data || []
              results = data.map((item) => {
                return { value: item }
              })
            }
            // 调用 callback 返回建议列表的数据
            cb(results)
          })
          .catch(() => {
            cb(results)
          })
      }, 500)
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  overflow: hidden;
}
#navigation {
  width: 100%;
  height: 40px;
  background: #f4f4f4;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
  margin-bottom: 22px;
}
</style>
