<template>
  <el-dialog :title="isEdit ? '编辑信息' : '上传'" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" ref="dataForm" :rules="dataRules" @keyup.enter.native="dataFormSubmit()" label-width="80px" autocomplete="off">
      <el-form-item prop="suit" label="产品系列">
        <el-select v-model="dataForm.suit" placeholder="产品系列" @change="suitChange" :disabled="isEdit">
          <el-option v-for="item in suitList" :key="item" :label="item" :value="item"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="产品信息" class="is-required">
        <span v-if="isEdit">{{ dataForm.pn }}</span>
        <el-tree
          v-else
          :data="pnTree"
          show-checkbox
          node-key="id"
          ref="pnTree"
          :props="treeProps"
          empty-text="请选择产品系列"
          :default-expand-all="true"
          :highlight-current="true"
          :expand-on-click-node="false"
          :check-on-click-node="true"
        >
        </el-tree>
      </el-form-item>
      <el-form-item label="版本固件" class="is-required">
        <el-upload
          ref="packUpload"
          :show-file-list="false"
          :on-change="handleChange"
          accept=".zip, .tar, .rar, .7z"
          action=""
          :auto-upload="false"
          :disabled="isEdit"
        >
          <el-button slot="trigger" type="text">{{ btnName }}</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="版本号" prop="otaVersion">
        <el-input v-model="dataForm.otaVersion" :disabled="true" placeholder="请上传版本固件"></el-input>
      </el-form-item>
      <el-form-item label="更新内容" prop="comment">
        <el-input v-model="dataForm.comment" placeholder="更新内容" maxlength="100" type="textarea"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="dataForm.remark" placeholder="备注" maxlength="100" type="textarea"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'DeviceAddOrUpdate',
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        suit: '',
        model: '',
        pn: '',
        otaVersion: '',
        comment: '',
        remark: ''
      },
      suitList: [],
      pnTree: [],
      treeProps: {
        label: 'displayName',
        children: 'children'
      },
      btnName: '选择文件...',
      dataRules: {
        suit: [{ required: true, message: '产品系列不能为空', trigger: 'change' }],
        otaVersion: [{ required: true, message: '版本号不能为空', trigger: 'blur' }],
        comment: [{ required: true, message: '更新内容不能为空', trigger: 'blur' }]
      },
      modelIds: [],
      otaPath: '',
      currentFile: {},
      isEdit: false
    }
  },
  methods: {
    init(row = {}) {
      this.pnTree = []
      this.btnName = '文件名称需包含版本号后缀 _Vn_n_n_n_n_n，例如XXX_V1_0_0_0_0_0.zip，版本号需高于现有版本'
      this.currentFile = {}

      // 系列
      this.$http({
        url: this.$http.adornUrl('/api/v1/production/suit'),
        method: 'get',
        params: this.$http.adornParams({ suit: '' })
      }).then((res) => {
        if (res.code === 0) {
          const data = res.data || []
          this.suitList = data
          this.visible = true
          this.$nextTick(() => {
            this.$refs.dataForm.resetFields()
            if (row.id) {
              this.isEdit = true
              this.dataForm = JSON.parse(JSON.stringify(row))
            } else {
              this.isEdit = false
              this.dataForm = {
                id: '',
                suit: '',
                model: '',
                pn: '',
                otaVersion: '',
                comment: '',
                remark: ''
              }
            }
          })
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    queryKey(queryString, cb, type) {
      if (this.timeOutId) {
        clearTimeout(this.timeOutId)
      }

      this.timeOutId = setTimeout(() => {
        let results = []
        let keyObj = {
          suit: queryString
        }
        this.$http({
          url: this.$http.adornUrl(`/api/v1/production/${type}`),
          method: 'get',
          params: this.$http.adornParams(keyObj)
        })
          .then((res) => {
            if (res.code === 0) {
              const data = res.data || []
              results = data.map((item) => {
                return { value: item }
              })
            }
            // 调用 callback 返回建议列表的数据
            cb(results)
          })
          .catch(() => {
            cb(results)
          })
      }, 500)
    },
    suitChange(val) {
      this.$http({
        url: this.$http.adornUrl('/api/v1/production/tree'),
        method: 'get',
        params: this.$http.adornParams({
          suit: val
        })
      }).then((res) => {
        if (res.code === 0) {
          const data = res.data || []
          this.modelIds = []
          const randSting = 'abcdefghij'
          data.forEach((item) => {
            item.id =
              randSting[Math.round(Math.random() * 10)] + new Date().getTime().toString().slice(-5) + randSting[Math.round(Math.random() * 10)]
            this.modelIds.push(item.id)
          })
          this.pnTree = data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleChange(file) {
      const fileName = file.name

      if (file.size > 3 * 1024 * 1024 * 1024) {
        this.$message.error('版本固件大小不能超过3GB，请调整后重新上传')
        return false
      }
      if (!/_V\d+_\d+_\d+_\d+_\d+_\d+\./.test(fileName)) {
        this.$message.error('版本固件不符合命名规则')
        return false
      }
      if (!fileName || ['zip', 'rar', 'tar', '7z'].indexOf(fileName.split('.')[fileName.split('.').length - 1]) === -1) {
        this.$message.error('版本固件文件格式错误')
        return false
      }

      this.currentFile = file.raw
      this.btnName = fileName
      this.dataForm.otaVersion = fileName.split('.')[0].substr(fileName.indexOf('V')).replace(/_/g, '.')
    },
    // 表单提交
    dataFormSubmit() {
      if (!this.isEdit && !Object.keys(this.currentFile).length) {
        this.$message.error('请选择版本固件')
        return
      }

      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          if (this.isEdit) {
            const { id, comment, remark } = this.dataForm
            this.$http({
              url: this.$http.adornUrl('/api/v1/ota/edit'),
              method: 'post',
              params: this.$http.adornParams({
                id,
                comment,
                remark
              })
            }).then((res) => {
              if (res.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            // 获取产品信息pn
            const pns = this.$refs.pnTree.getCheckedKeys().filter((pn) => {
              return this.modelIds.indexOf(pn) === -1
            })
            if (pns.length === 0) {
              this.$message.error('请选择产品信息')
              return
            }

            const modelAndPn = {}
            for (let i = 0; i < pns.length; i++) {
              for (let j = 0; j < this.pnTree.length; j++) {
                const pnList = this.pnTree[j].children || []
                let findFlag = false
                for (let k = 0; k < pnList.length; k++) {
                  if (pns[i] === pnList[k].id) {
                    const pnNames = modelAndPn[this.pnTree[j].displayName] || []
                    pnNames.push(pnList[k].displayName)
                    modelAndPn[this.pnTree[j].displayName] = pnNames
                    findFlag = true
                    break
                  }
                }
                if (findFlag) break
              }
            }

            // 校验版本号
            this.$http({
              url: this.$http.adornUrl('/api/v1/ota/beginUpload'),
              method: 'post',
              data: this.$http.adornData({
                suit: this.dataForm.suit,
                modelAndPn,
                version: this.dataForm.otaVersion
              })
            }).then((res) => {
              if (res.code === 0) {
                // 校验当前产品是否有正在上传的包
                const uploadingPnList = this.$store.state.uploadingPnList || {}
                const failedPnList = this.$store.state.failedPnList || {}
                let replaceId = 0

                for (let i = 0; i < pns.length; i++) {
                  if (uploadingPnList[pns[i]]) {
                    this.$message({
                      message: '当前产品存在未完成的版本固件上传任务，请等待任务结束后再上传',
                      type: 'error',
                      duration: 3000
                    })
                    return
                  }

                  if (failedPnList[pns[i]]) {
                    replaceId = failedPnList[pns[i]]
                    break
                  }
                }

                // 接下来
                const theNext = () => {
                  const pnNames = []
                  const modelNames = []
                  for (const key in modelAndPn) {
                    modelNames.push(key)
                    pnNames.push(...modelAndPn[key])
                  }

                  const uploadObj = {
                    // 文件
                    file: this.currentFile,
                    // 业务参数
                    params: {
                      suit: this.dataForm.suit,
                      modelAndPn,
                      pns,
                      pnNames: pnNames.toString(),
                      modelNames: modelNames.toString(),
                      otaVersion: this.dataForm.otaVersion,
                      comment: this.dataForm.comment,
                      remark: this.dataForm.remark
                    },
                    // 其他参数
                    replaceId
                  }

                  this.$store.commit('addUpload', uploadObj)
                  this.visible = false
                }

                if (replaceId) {
                  this.$confirm(
                    `<div >
                      <div class="tmc_confirm_icon"></div>
                      <div class="tmc_confirm_title">提示</div> 
                      <div class="tmc_confirm_content">当前所选产品存在已取消或失败的版本固件上传任务，确认替换任务吗？</div>
                    </div>`,
                    {
                      confirmButtonText: '确定',
                      cancelButtonText: '取消',
                      dangerouslyUseHTMLString: true,
                      center: true,
                      customClass: 'tmc_confirm'
                    }
                  )
                    .then(() => {
                      theNext()
                    })
                    .catch(() => {})
                } else {
                  theNext()
                }
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        }
      })
    }
  }
}
</script>
